import axios from 'axios';
import React, { useState } from 'react'
import { Button as BButton, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import img from '../assets/images/poster.jpg'

const VoterInfo = () => {
    const [voters, setVoters] = useState([]);
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [ward, setWard] = useState("");
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);
    const [wrongInput, setWrongInput] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [SMSID, setSMSID] = useState("");
    const [number, setNumber] = useState("");
    const [smsError, setSmsError] = useState("");
    const [wrongNumber, setWrongNumber] = useState(false);
    const [smsLoading, setSmsLoading] = useState(false);

    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(2);

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleInputDOB = (e) => {
        const convertedInput = convertToBengaliDigits(e.target.value);
        const formattedInput = formatBengaliDateWhileTyping(convertedInput);
        setDob(formattedInput);
        formattedInput.length > 10 ? setWrongInput(true) : setWrongInput(false);
        formattedInput.length > 10 ? setBtnDisabled(true) : setBtnDisabled(false);
    }

    function convertToBengaliDigits(input) {
        const englishDigits = '0123456789';
        const bengaliDigits = '০১২৩৪৫৬৭৮৯';

        for (let i = 0; i < englishDigits.length; i++) {
            input = input.replace(new RegExp(englishDigits[i], 'g'), bengaliDigits[i]);
        }

        return input;
    }

    function formatBengaliDateWhileTyping(input) {
        // Remove existing hyphens and slashes
        input = input.replace(/[/-]/g, '');

        let formattedInput = '';
        for (let i = 0; i < input.length; i++) {
            formattedInput += input[i];
            if (i === 1 || i === 3) {
                formattedInput += '/';
            }
        }

        return formattedInput;
    }

    const handleBackspace = (e) => {
        if (e.key === 'Backspace') {
            const input = e.target.value;
            if (input.length > 0) {
                const lastChar = input.slice(-1);
                if (lastChar === '/' || lastChar === '০' || lastChar === '১' || lastChar === '২' || lastChar === '৩' || lastChar === '৪' || lastChar === '৫' || lastChar === '৬' || lastChar === '৭' || lastChar === '৮' || lastChar === '৯') {
                    setDob(input.slice(0, -1));
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await axios.post('https://viparvez.com/public/api/search',
                {
                    name,
                    dob,
                    ward
                })
            if (data.success === true && data?.data?.length > 0) {
                setLoading(false);
                setNoData(false);
                setVoters(data.data);
                e.target.reset();
                setName("");
                setDob("");
                setWard("");
            }
            else {
                setLoading(false);
                setNoData(true);

            }
        } catch (error) {
            setLoading(false);
            setNoData(true);
        }
    }

    const handleSMS = (id) => {
        setModalShow(true);
        setSMSID(id);
    }

    const handleSubmitSMS = async (e) => {
        e.preventDefault();
        setSmsLoading(true);
        try {
            const { data } = await axios.post('https://viparvez.com/public/api/sendsms',
                {
                    id: SMSID,
                    to: number
                })
            if (data.success === true) {
                setSmsLoading(false);
                e.target.reset();
                setNumber("");
                setModalShow(false);
            }
            else {
                setSmsLoading(false);
                setSmsError(data?.errors?.sms);
            }
        } catch (error) {
            setSmsLoading(false);
            setSmsError(error?.response?.data?.errors?.sms);
        }
    }

    const handleInfoDownload = (voter) => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        const img = document.getElementById("canvas-img");
        ctx.drawImage(img, 0, 0);
        ctx.font = "bold 20px Arial"
        ctx.fillText(voter.serial ? voter.serial : "", 800, 370);
        ctx.fillText(voter.center ? voter.center.slice(0, 66) : "", 800, 415);
        ctx.fillText(voter.center ? voter.center.slice(66, 132) : "", 800, 450);
        ctx.fillText(voter.voter_area ? voter.voter_area : "", 800, 490);
        ctx.fillText(voter.ward ? voter.ward : "", 800, 535);
        ctx.fillText(voter.name ? voter.name : "", 800, 580);
        ctx.fillText(voter.nid ? voter.nid : "", 800, 620);
        ctx.fillText(voter.father ? voter.father : "", 800, 665);
        ctx.fillText(voter.mother ? voter.mother : "", 800, 710);
        ctx.fillText(voter.dob ? voter.dob : "", 800, 755);
        ctx.fillText(voter.address ? voter.address : "", 800, 800);
        const url = canvas.toDataURL("image/jpg");
        const link = document.createElement('a');
        link.download = 'poster.jpg';
        link.href = url;
        link.click();
    }

    return (
        <div id="voter-info-search" className='voter-info'>

            <img
                alt=""
                id='canvas-img'
                className='d-none'
                src={img}
            />

            <canvas
                id='canvas'
                width="1600"
                height="901"
                className='d-none'
            />

            <div className='title'>ভোটকেন্দ্রের নাম খুঁজুন</div>

            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row className='my-5'>
                        <Col className='my-3' sm={12} md={4}>
                            <Form.Select
                                required
                                className='py-3'
                                aria-label="select ward"
                                value={ward}
                                onChange={(e) => setWard(e.target.value)}
                            >
                                <option disabled value="">ওয়ার্ড</option>
                                <option value="০৭">ওয়ার্ড নং ০৭</option>
                                <option value="০৮">ওয়ার্ড নং ০৮</option>
                                <option value="০৯">ওয়ার্ড নং ০৯</option>
                                <option value="১০">ওয়ার্ড নং ১০</option>
                                <option value="১১">ওয়ার্ড নং ১১</option>
                                <option value="১২">ওয়ার্ড নং ১২</option>
                                <option value="১">কাউন্দিয়া ইউনিয়ন</option>
                            </Form.Select>
                        </Col>
                        <Col className='my-3' sm={12} md={4}>
                            <Form.Control
                                required
                                className='py-3'
                                placeholder="জন্ম তারিখ(দিন-মাস-বছর)"
                                value={dob}
                                onChange={(e) => handleInputDOB(e)}
                                onKeyDown={(e) => handleBackspace(e)}
                            />
                            {wrongInput && <span className='text-danger'>অনুগ্রহ পূর্বক সঠিক তথ্য প্রবেশ করুন</span>}
                        </Col>
                        <Col className='my-3' sm={12} md={4}>
                            <Form.Control
                                placeholder="নাম (বাংলায় লিখুন)"
                                className='py-3'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-center'>
                        <BButton
                            type='submit'
                            size='sm'
                            variant='dark'
                            disabled={btnDisabled ? true : false}
                            className={`px-5 py-2 ${loading ? 'd-none' : 'd-block'}`}
                        >
                            ভোটকেন্দ্র খুজুন
                        </BButton>
                        <BButton
                            disabled
                            type='submit'
                            size='sm'
                            variant='dark'
                            className={`px-5 py-2 ${loading ? 'd-block' : 'd-none'}`}
                        >
                            অপেক্ষা করুন
                        </BButton>
                    </div>
                </Form>
            </Container>

            {(voters.length > 0 && !noData) &&
                <div className='d-none d-md-block container mt-5'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>ক্রমিক</StyledTableCell>
                                    <StyledTableCell align="center">নাম</StyledTableCell>
                                    <StyledTableCell align="center">পিতা/স্বামী</StyledTableCell>
                                    <StyledTableCell align="center">জন্ম তারিখ</StyledTableCell>
                                    <StyledTableCell align="center">ঠিকানা</StyledTableCell>
                                    <StyledTableCell align="center">ভোট কেন্দ্র</StyledTableCell>
                                    <StyledTableCell align="center">SMS</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                {voters.map((row) => (
                                    <StyledTableRow key={row.serial}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.serial}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.father}</StyledTableCell>
                                        <StyledTableCell align="center">{row.dob}</StyledTableCell>
                                        <StyledTableCell align="center">{row.address}</StyledTableCell>
                                        <StyledTableCell align="center">{row.center}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    paddingY: '0px',
                                                    marginBottom: '8px',
                                                    backgroundColor: '#bf0d3e',
                                                    '&:hover': { backgroundColor: '#bf0d3e' }
                                                }}
                                                onClick={() => handleSMS(row.id)}
                                            >
                                                বার্তা পাঠান
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    paddingY: '0px',
                                                    backgroundColor: '#bf0d3e',
                                                    '&:hover': { backgroundColor: '#bf0d3e' }
                                                }}
                                                onClick={() => handleInfoDownload(row)}
                                            >
                                                সংরক্ষন করুন
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*
                    <TablePagination
                        rowsPerPageOptions={[2, 5, 10]}
                        component="div"
                        count={voters.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    */}
                </div>}

            {(voters.length > 0 && !noData) &&
                <div className='d-block d-md-none mt-5'>
                    <Container>
                        {voters.map(voter =>
                            <Card
                                bg='light'
                                text='dark'
                                className="mb-3"
                                key={voter.serial}
                            >
                                <Card.Body>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>ক্রমিক</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.serial}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>নাম</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.name}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>পিতা/স্বামী</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.father}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>জন্ম তারিখ</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.dob}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>ঠিকানা</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.address}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <Card.Title>ভোট কেন্দ্র</Card.Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Text>{voter.center}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col className='text-end'>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    paddingY: '0px',
                                                    backgroundColor: '#bf0d3e',
                                                    '&:hover': { backgroundColor: '#bf0d3e' }
                                                }}
                                                onClick={() => handleSMS(voter.id)}
                                            >
                                                বার্তা পাঠান
                                            </Button>
                                        </Col>
                                        <Col className='text-start'>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    paddingY: '0px',
                                                    backgroundColor: '#bf0d3e',
                                                    '&:hover': { backgroundColor: '#bf0d3e' }
                                                }}
                                                onClick={() => handleInfoDownload(voter)}
                                            >
                                                সংরক্ষন করুন
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Container>
                </div>}

            {noData &&
                <Container>
                    <Card
                        bg='danger light'
                        text='light'
                        className="mt-5 mb-3 fw-bold"
                    >
                        <Card.Body>
                            কোনো তথ্য খুঁজে পাওয়া যায়নি
                        </Card.Body>
                    </Card>
                </Container>}

            <Modal
                centered
                size="md"
                show={modalShow}
                onHide={() => { setModalShow(false); setSmsError("") }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <h6>ভোট কেন্দ্রের তথ্য পেতে আপনার ফোন নম্বর লিখুন</h6>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitSMS}>

                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='w-75'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            pointerEvents: 'none',
                                            borderRadius: '0px',
                                            background: 'rgb(33, 37, 41)'
                                        }}
                                    >
                                        +880
                                    </Button>
                                    <Form.Control
                                        required
                                        type='number'
                                        placeholder="মোবাইল নাম্বার লিখুন (ইংরেজিতে)"
                                        value={number}
                                        className='rounded-0'
                                        onChange={(e) => {
                                            setNumber(e.target.value);
                                            (e.target.value[0] !== "1" || e.target.value[1] === "0" || e.target.value[1] === "1" || e.target.value[1] === "2" || e.target.value.length > 10) ? setWrongNumber(true) : setWrongNumber(false);
                                        }}
                                    />
                                </div>
                                <div className='my-1'>
                                    {wrongNumber && <span>অনুগ্রহ পূর্বক সঠিক নাম্বার প্রবেশ করুন</span>}
                                    <br />
                                    <span>{smsError}</span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center align-items-center'>
                            <BButton
                                type='submit'
                                variant='dark'
                                disabled={wrongNumber ? true : false}
                                className={`px-5 ${smsLoading ? 'd-none' : 'd-block'}`}
                            >
                                বার্তা পাঠান
                            </BButton>
                            <BButton
                                disabled
                                type='submit'
                                variant='dark'
                                className={`px-5 ${smsLoading ? 'd-block' : 'd-none'}`}
                            >
                                অপেক্ষা করুন
                            </BButton>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default VoterInfo