import React from 'react'
import Banner from '../components/Banner'
import VoterInfo from '../components/VoterInfo'
import Message from '../components/Message'
import Footer from '../components/Footer'

const Home = () => {
    return (
        <React.Fragment>
            <Banner />
            <VoterInfo />
            <Message />
            <Footer />
        </React.Fragment>
    )
}

export default Home