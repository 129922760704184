import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="bg-dark text-center py-5">
                <p>
                    <a
                        href="http://innovisionlabsbd.com"
                        target="_blank"
                        rel="noreferrer"
                        className='text-white text-decoration-none'
                    >
                        &#169; InnovisionLabsBD
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer